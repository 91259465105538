<template>
    <a-modal
        :visible="visible"
        title="Unggah Suggestion Toko Lelang"
        @cancel="handleModalCancel"
        width="80%"
        :destroy-on-close="true"
        :footer="null">

        <AForm
            ref="formRef"
            class="myform"
            @submit.prevent="btnUploadFile()"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Unggah Dokumen"
                        label-align="left"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        has-feedback>
                        <ATooltip
                            title="Template Unggah Suggestion Toko Lelang bisa didapatkan dari Hasil Download menu Suggestion Toko Lelang"
                            placement="topLeft">
                            <AInput
                                v-if="state.isForm"
                                type="file"
                                required
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                @change="onFileChange"/>
                        </ATooltip>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row class="form-row mb-4">
                <ACol :sm="4"/>
                <a-col :sm="20">
                    <ASpace>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="state.form.loading">Upload</a-button>
                        <!-- <AButton
                            @click="btnDownloadTemplate()"
                            :loading="state.loadingTemplate">Unduh Template</AButton> -->
                    </ASpace>
                </a-col>
            </a-row>
        </AForm>

        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <br>

        <AForm
            ref="formRefTable"
            class="myform"
            :model="form"
            :rules="state.rules"
            @finish="handleSubmitOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                row-key="suggestion_id"
                :row-selection="rowSelection"
                size="small"
                :scroll="{ x: 'calc(700px + 80%)', y: 600 }"
                :loading="state.loading">
                <template #tags="{ record }">
                    <a-tag :color="record.errors.length == 0 ? 'green' : 'volcano'">
                    <template #icon>
                        <template v-if="record.errors.length == 0"> <CheckCircleOutlined /> Ya </template>
                        <template v-else>
                            <APopover trigger="click">
                                <template #content>
                                    <AList size="small" :data-source="record.errors">
                                        <template #renderItem="{ item }">
                                            <AListItem>{{ item }}</AListItem>
                                        </template>
                                    </AList>
                                </template>
                                <CloseCircleOutlined /> Tidak
                            </APopover>
                        </template>
                    </template>
                    </a-tag>
                </template>

                <template
                    #upload="{ index, text }">

                    <AFormItem
                        required
                        :name="['files', index, 'file']">
                        <AInput
                            type="file"
                            v-model:value="text.file"
                            accept="image/png, image/gif, image/jpeg"
                            :disabled="text.action != 'delete'"
                            @change="(file) => listenBeforeUpload(file, index)"/>
                    </AFormItem>

                    <AImage
                        v-if="text.image_url"
                        :src="text.image_url"
                        style="width:100%"
                        class="mt-2"/>
                </template>
            </MdTable>

            <ARow class="form-row mt-4" type="flex" justify="end">
                <ASpace>
                    <AButton @click="handleModalCancel">Batal</AButton>
                    <AButton
                        type="primary"
                        html-type="submit"
                        :loading="state.loading">Submit</AButton>
                </ASpace>
            </ARow>
        </AForm>

         <AModal
            v-model:visible="state.result.isShow"
            title="Result"
            @cancel="handleShowCancel"
            @ok="handleShowCancel">
            <template #footer>
                <AButton key="back" type="primary" @click="handleShowCancel">OK</AButton>
            </template>
            <AAlert type="success" :message="`Approve: ${state.result.valid}`"></AAlert>
            <AAlert type="error">
                <template #message>
                {{ `Reject: ${state.result.failed}` }}

                <p
                    v-for="(item, index) in state.result.data_reject"
                    :key="index">{{ item.title }}</p>
                </template>
            </AAlert>
            <AAlert
                v-has-role="[ROLE_TSO]"
                type="error">
                <template #message>
                {{ `Delete: ${state.result.delete}` }}

                <p
                    v-for="(item, index) in state.result.data_delete"
                    :key="index">{{ item.title }}</p>
                </template>
            </AAlert>
        </AModal>

    </a-modal>
</template>
<script>
import {
    defineComponent,
    nextTick,
    reactive,
    ref,
    computed,
} from 'vue'
import apiClient from '@/services/axios'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue'
import { Modal, message } from 'ant-design-vue'
import Form from 'vform'
import moment from 'moment'
import {
    lowerCase,
} from 'lodash'
import {
    ROLE_TSO,
    ROLE_SPC,
    hasRoles,
} from '@/helpers'

export default defineComponent({
    components: {
        CheckCircleOutlined,
        CloseCircleOutlined,
    },
    props: {
        visible: [Boolean],
    },
    emits: ['btnUploadFileConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const errorMessage = ref()

        const formRef = ref();
        const formRefTable = ref();

        const form = reactive(new Form({
            files: [],
        }))

        const state = reactive({
            columns: [
                {
                    title: 'Valid?',
                    dataIndex: 'valid',
                    slots: {
                        customRender: 'tags',
                    },
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                },
                {
                    title: 'Upload',
                    width: 200,
                    slots: { customRender: 'upload' },
                },
                {
                    title: 'Note',
                    dataIndex: 'note',
                },
                {
                    title: 'Periode',
                    dataIndex: 'periode',
                },
                {
                    title: 'Region',
                    dataIndex: 'regional',
                },
                {
                    title: 'ID Toko',
                    dataIndex: 'customer_code',
                },
                {
                    title: 'Nama Toko',
                    dataIndex: 'customer_name',
                },
                {
                    title: 'Alamat',
                    dataIndex: 'address',
                },
                {
                    title: 'Area',
                    dataIndex: 'area',
                },
                {
                    title: 'Kota',
                    dataIndex: 'kota',
                },
                {
                    title: 'Provinsi',
                    dataIndex: 'provinsi',
                },

                {
                    title: 'SSM',
                    dataIndex: 'ssm',
                },
                {
                    title: 'ASM',
                    dataIndex: 'sm',
                },
                {
                    title: 'TSO',
                    dataIndex: 'am',
                },
                {
                    title: 'Distributor Mapping',
                    dataIndex: 'distributor_mapping',
                },
                {
                    title: 'Average Volume Toko (2 Bulan)',
                    dataIndex: 'avg_volume',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                },
                {
                    title: 'Catatan TSO',
                    dataIndex: 'catatan_am',
                },
            ],
            rules: {
                files: [],
            },
            rule_references: {
                file: [
                    {
                        required: true,
                        message: "File tidak boleh kosong!",
                    },
                ],
            },
            data: [],
            loading: false,
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            selectedRowKeys: [],
            selectedRows: [],
            result: {
                isShow: false,
                data_reject: [],
                data_delete: [],
                valid: '',
                failed: '',
                delete: '',
            },
            form: {
                loading: false,
                files: null,
                file: null,
            },
            isForm: true,
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
        }

        const onFileChange = (e) => {
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return
            state.form.file = files[0]
        }

        const rowSelection = computed(() => {
            return {
                selectedRowKeys: state.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    state.selectedRowKeys = selectedRowKeys
                    state.selectedRows = selectedRows
                },
                getCheckboxProps: record => ({
                    disabled: record.errors.length > 0,
                }),
            }
        })

        const btnUploadFile = async () => {
            // validation form
            await formRef.value.validate()
                .catch(() => { });
            
            state.form.loading = true

            const form_data = new FormData()

            form_data.append('file', state.form.file)

            apiClient.post(`/api/report-lelang/upload-approval`, form_data)
                .then(({ data }) => {
                    data.forEach(item => {
                        state.rules.files.push(Object.assign({}, state.rule_references))
                        form.files.push(Object.assign({}, {file: null}))
                    })
                    state.data = data
                })
                .catch(async error => {
                    errorMessage.value = null

                    if (error.response && error.response.status !== 500) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }

                    if (error.response && error.response.status === 500) {
                        const { status, statusText } = error.response
                        const message = error.response.data.message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }

                    message.error('Gagal mengungah!')
                })
                .finally(() => {
                    state.form.loading = false

                    state.isForm = false
                    nextTick().then(() => {
                        state.isForm = true
                    })
                })
        }

        const handleSubmitOk = async () => {
            // validation form
            await formRefTable.value.validate()
                .catch(() => { });

            const payloads = new FormData()
            let handleRequideImage = []

            state.selectedRows.forEach((item, index) => {
                payloads.append(`simpan[${index}][action]`, item.action)
                payloads.append(`simpan[${index}][suggestion_id]`, item.suggestion_id)
                payloads.append(`simpan[${index}][note]`, item.note)
                payloads.append(`simpan[${index}][status_approval]`, item.status_approval)
                payloads.append(`simpan[${index}][valid]`, item.valid)
    
                if (item.action == 'delete' && item.image) {
                    payloads.append(`simpan[${index}][image]`, item.image);
                } else {
                    handleRequideImage.push({
                        title: `gambar wajib diisi pada row data ke - ${index+1}`,
                        key: index,
                    })
                }
            })

            if (handleRequideImage.length) {
                state.result.isShow = true
                state.result.data_delete = handleRequideImage

                return
            }

            state.result.data = []
            errorMessage.value = null
            state.loading = true

            apiClient
                .post('/api/report-lelang/upload-approval', payloads)
                .then(({ data }) => {
                    if (Array.isArray(data) && data.length === 0) {
                        message.warning('Tidak ada data!, pastikan sudah memilih datanya')
                        return
                    }

                    state.result.isShow = true

                    data.forEach((item, index) => {
                        if ((item.action == "reject" && !item.success) || (item.errors)) {
                            item.errors.forEach((v, i) => {
                                state.result.data_reject.push({
                                    title: v,
                                    key: i,
                                })
                            })
                        } else if ((item.action == "reject" && item.success) || (item.errors)) {
                            state.result.failed = item.message
                        } else if ((item.action == "delete" && !item.success) || (item.errors)) {
                            item.errors.forEach((v, i) => {
                                state.result.data_delete.push({
                                    title: v,
                                    key: i,
                                })
                            })
                        } else if ((item.action == "delete" && item.success) || (item.errors)) {
                            state.result.delete = item.message
                        } else if ((item.action == "approve" && item.success)) {
                            state.result.valid = item.message
                        }
                    })
                    
                    emit('success', data)
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = error.response.data.message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.loading = false
                    state.selectedRowKeys = []
                    state.selectedRows = []
                })
        }

        const btnDownloadTemplate = async () => {
            state.loadingTemplate = true

            apiClient
                .post('/api/visit-plan/unduh-template', null, {
                    params: {
                        _export: 'xls',
                    },
                    responseType: 'blob',
                    headers: {},
                })
                .then(response => {
                    if (response === undefined) {
                        errorMessage.value = `Kode error 500, No response from server`
                        return
                    }
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }),
                    )
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `Master-Visit-Plan_${moment().format('DDMMYY_HHmm')}.xlsx`)
                    document.body.appendChild(link)
                    link.click()
                })
                .catch(async error => {
                    errorMessage.value = null
                    if (error.response) {
                        const { status, statusText } = error.response
                        const message = JSON.parse(await error.response.data.text()).message
                        errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
                    }
                })
                .finally(() => {
                    state.loadingTemplate = false
                })
        }

        const handleShowCancel = () => {
            state.result.isShow = false
        }

        const listenBeforeUpload = (e, index) => {
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return

            let first = state.data.find((item, i) => i == index)
            let url = URL.createObjectURL(files[0])

            if (first && url) {
                first.image_url = url
                first.image = files[0]
                form.files[index].file = files[0]
            }
        }

        return {
            errorMessage,
            state,
            formRef,
            formRefTable,
            form,
            btnUploadFile,
            handleSubmitOk,
            handleModalCancel,
            onFileChange,
            rowSelection,
            btnDownloadTemplate,
            handleShowCancel,
            listenBeforeUpload,
            // only role
            ROLE_TSO,
            ROLE_SPC,
            hasRoles,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
